.upload-Multiple:hover{
    background-color: #DDF1FF;
    }
    .css-132hsfj-MuiFormControl-root-MuiTextField-root {
        width: 100%;
    }
    
    .headingName {
        font-size: 64px;
    }
    
    .social-support-text {
        font-style: normal;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
    }
    
    .social-textField {
        background-color: #F8F8F8;
        width: 100% !important;
        font-size: 1rem !important;
        line-height: 20px !important;
        font-weight: 500 !important;
    }
    .social-Field{
        width: 100% !important;
    }
    
    .social-up-main-div {
        text-align: center;
        /* text-align: -webkit-center -moz-center; */
    }
    
    .social-img-wrap {
        position: relative;
        /* background-color: #EAEAEA */
    }
    
    .rating-sub {
        /* background-color: white; */
        border-radius: 15px;
    }
    .MuiFormHelperText-root{
        margin-left: 8px !important;
    }
    
    @media only screen and (min-width: 0px) and (max-width: 500px) {
        .headingName {
            font-size: 44px;
        }
    }
    
    
    @media only screen and (min-width: 501px) and (max-width: 700px) {
        .headingName {
            font-size: 54px;
        }
    }
    
    @media only screen and (min-width: 701px) and (max-width: 900px) {}
    
    @media only screen and (min-width: 901px) and (max-width: 1200px) {}
    
    @media only screen and (min-width: 1201px) and (max-width: 1400px) {}
    
    @media only screen and (min-width: 1401px) and (max-width: 2000px) {}
    
    @media only screen and (min-width: 901px) {}
    
    
    
    
    
    
    
    
    
    
    
    
    
    .rating-main-container {
        background-color: #EAEAEA;
        padding: 10px;
        display: flex;
        flex-direction: column;
    }
    
    .rating-sub-container {
        display: flex;
        width: 100%;
        margin-top: 1%;
        margin-bottom: 1%;
    }
    
    .rating-div {
        background-color: white;
        height: 15px;
        border-radius: 15px;
        padding: 15px;
        display: flex;
        width: 85%;
    }
    
    .rating-text {
        margin-left: 3% !important;
    }
    
    .rating-icons {
        margin-left: 51%;
        position: absolute;
    }
    
    .rating-cancel {
        padding-left: 16px;
    }
    
    
    /*(min-width: 0px) and (max-width: 500px) */
    
    @media only screen and (min-width: 0px) and (max-width: 500px) {}
