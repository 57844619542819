.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .loader-image {
    margin-bottom: 20px;
  }
  
  .loader-bar {
    width: 100px; /* Adjust width as needed */
    height: 10px;
    background-color: #0a66c2;
    animation: loader-animation 1.5s linear infinite; /* Linear animation for progressive effect */
  }
  
  @keyframes loader-animation {
    0% {
      width: 0;
      margin-left: 0; /* Start from left */
    }
    100% {
      width: 100%;
      margin-left: 0; /* Move to right */
    }
  }
  