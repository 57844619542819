.tile-video {
  position: relative;
  width: 480px;
  height: 270px !important;
  border-radius: 6px;
  overflow: hidden;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .tile-video {
    width: 240px;
    height: 135px;
  }
}

.self-view {
  width: 480px;
  height: 270px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .self-view {
    width: 240px;
    height: 135px;
  }
}

.no-video {
  background-color: #888;
  background-image: url('../../../images/LuteFish_Logo_UI_withtagline.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.no-video video {
  visibility: hidden;
}

/* Hard-code the self-view size if there's no one else in the call, just to make it align with the info-box */
.self-view.alone {
  width: 480px;
  height: 270px;
}

@media only screen and (max-width: 600px) {
  .self-view.alone {
    width: 240px;
    height: 135px;
  }
}

/* Put screen share front and center in our grid when there is one active */
.tile-screenshare {
  grid-column: 1 / -1;
  grid-row: 1;
}

.video-container .visibility-check {
  visibility: hidden; /* Initially hidden */
  opacity: 0;
}

.video-container:hover .visibility-check {
  visibility: visible; /* Visible on hover */
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 1;
}
